.special {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 20px;
  font-size: 14px;
}
.tc_main {
  font-size: 14px;
  color: #000;
  font-family: Kodchasan;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  width: 60%;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 5%;
  margin-left: 5%;
}
.applies {
  color: #000;
  font-family: Kodchasan;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}
.agree {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 20px;
}

.savebtn {
  width: 100%;
  right: 0;
  margin-top: 10px;
  margin-left: 20%;
}
.bordermain {
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  padding: 5px;
}
@media (max-width: 500px) {
  .tc_main {
    width: 90%;
    font-size: 14px;
    margin-left: 0;
  }
}
@media (min-width: 500px) and (max-width: 1300px) {
  .tc_main {
    margin-left: 0;
    font-size: 14px;
    width: 60%;
  }
}
