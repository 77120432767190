.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-left: 2%;
  margin-bottom: 3%;
  height: 80vh;
}
.sidenav_card {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  justify-content: flex-start;
  gap: 10%;
}
.maindiv {
  background-color: #f9f9f9;
}
.error {
  align-items: center;
  justify-content: center;
  margin-left: 20%;
  margin-top: 20%;
}
