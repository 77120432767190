.image_profile {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  align-items: center;
}
img {
  width: 150px;
}
.view_cards {
  display: flex;
  justify-content: space-between;
  width: 600px;
  align-items: self-start;
  padding: 5px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 4px 4px 4.7px 0px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
}
.text {
  width: 100%;
  padding-top: 2px;
}
p {
  padding: 6px;
}
button {
  display: flex;
  justify-content: center;
  font-weight: 300;
  gap: 5px;
  background-color: #2ca2ad;
}
.buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.editicon {
  background-color: green;
}
.deleteicon {
  background-color: red;
}
@media (max-width: 500px) {
  .view_cards {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .buttons {
    flex-direction: column;
  }
  .image_profile {
    margin: 0;
  }
}
@media (min-width: 501px) and (max-width: 850px) {
  .view_cards {
    width: 80%;
  }
  .buttons {
    flex-direction: column;
  }
}
