.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: self-start;
  margin-left: 2%;
  margin-bottom: 3%;
  height: 80vh;
  margin-top: 1%;
}
.sidenav_card {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  justify-content: flex-start;
  gap: 2%;
  margin-bottom: 1%;
  height: 89vh;
  overflow-y: scroll;
}
@media (max-width: 500px) {
  .cards {
    width: 90%;
    justify-content: center;
  }
  .sidenav_card {
    justify-content: center;
    width: 100%;
  }
}
