button {
  background-color: #2ca2ad;
  padding: 12px 30px;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  font-family: "Kodchasan";
  box-sizing: border-box;
  transition: ease-in 100ms;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  font-weight: 300;
}
button:hover {
  background-color: #47bec8;
}
