.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-left: 2%;
  margin-top: 1%;
  width: 80%;
  height: 85vh;
  margin-bottom: 2%;
}
.sidenav_card {
  display: flex;
  flex-direction: row;
  align-items: self-start;
}
.maindiv {
  background-color: #f9f9f9;
  overflow-y: hidden;
}
