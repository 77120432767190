.card {
  width: 200px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  padding-bottom: 0;
  padding-left: 0;
  cursor: pointer;
}

.profile-image {
  width: 200px;
  height: 160px;
  margin-left: 5px;
}
.body {
  text-align: center;
  width: 100%;
}
.card_number {
  border-radius: 0px 0px 5px 5px;
  background: #2ca2ad;
  width: 100%;
  padding: 10px 3px;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: 500;
  margin-bottom: 0;
}
h2,
p {
  margin-bottom: 0;
}
@media (max-width: 500px) {
  .card {
    width: 200px;
    
  }
  .profile-image {
    width: 150px;
    height: 120px;
  }
}
