.add_card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 600px;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  margin-top: 1%;
  margin-left: 5%;
  margin-bottom: 2%;
  height: 90vh;
  overflow-y: scroll;
}
.uploadpic {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.uploadpic img {
  width: 150px;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}
.input {
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 14px;
  flex: 1 0 0;
  align-self: stretch;
}
input,
select {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #747171;
}
label {
  display: flex;
  width: 160px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.save {
  display: flex;
  width: 80%;
  padding: 12px 29px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.save button {
  width: 30%;
}

.error_input {
  border-color: red;
  color: red;
}
select {
  width: 376px;
  height: 30px;
}
.modal {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.uploadbtn {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
@media (max-width: 500px) {
  input,
  select {
    width: 250px;
  }
  .uploadbtn {
    flex-direction: column;
  }
  .uploadpic img {
    width: 150px;
  }
}
